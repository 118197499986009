var languageMap = {
    "reputationmanager.io": {
        'title': "Reputation Manager",
        'href': 'https://reputationmanager.io/images/repute-logo.png',
        'language': "english",
        'description': "Reputation Manager",
    },
    "dev.localclarity.com": {
        'title': "Dev - Localclarity",
        'href': 'https://dev.localclarity.com/images/favicon.ico',
        'language': "english",
        'description': "Localclarity",
    },
    "mig.localclarity.com": {
        'title': "Mig - Localclarity",
        'href': 'https://dev.localclarity.com/images/favicon.ico',
        'language': "english",
        'description': "Localclarity",
    },
    "app.localclarity.com": {
        'title': "Localclarity",
        'href': 'https://app.localclarity.com/images/favicon.ico',
        'language': "english",
        'description': "Localclarity",
    },
    "app.gearseo.com.br": {
        'title': "Gear SEO Local: Gerenciamento de reputação para marcas",
        'href': 'https://app.gearseo.com.br/images/faviicon.png',
        'language': "portuguese",
        'description': "Com o Gear SEO Local você terá total gerenciamento e controle da sua marca nas principais plataformas de comentários na internet. Conheça agora!",
    },
    "localhost:8000": {
        'title': "Localhost",
        'href': 'https://dev.localclarity.com/images/favicon.ico',
        'language': "english",
        'description': "Localhost",
    },
    "localhost:3000": {
        'title': "beta",
        'href': 'https://dev.localclarity.com/images/favicon.ico',
        'language': "english",
        'description': "Localhost",
    },
    "reviews.insiderperks.com": {
        'title': "Insiderperks",
        'href': 'https://reviews.insiderperks.com/images/faviconsperks.png',
        'language': "english",
        'description': "insiderperks",
    },
    "reviews.10thousandfeet.com": {
        'title': "10 thousandfeet",
        'href': 'https://reviews.10thousandfeet.com/images/10000fav.png',
        'language': "english",
        'description': "10 thousandfeet",
    },
    "gmb.insyghts.io": {
        'title': "Insyghts.io",
        'href': 'https://gmb.insyghts.io/images/faviconinsyght.png',
        'language': "english",
        'description': "A Better Google My Business Portal",
    },
    "reputationmanager.freshfeedback.ca": {
        'title': "Freshfeedback",
        'href': 'https://reputationmanager.freshfeedback.ca/images/faviconfresh.png',
        'language': "english",
        'description': "Freshfeedback",
    },
    "app.reputate.co.za": {
        'title': "reputate",
        'href': 'https://app.reputate.co.za/images/faviconreputate.png',
        'language': "english",
        'description': "reputate",
    },
    "insights.setmysite.com": {
        'title': "SetMySite",
        'href': 'https://insights.setmysite.com/images/faviconsetmysite.png',
        'language': "english",
        'description': "website design and website management services",
    },
    "local.mickeyllew.co.za": {
        'title': "Mickey Llew",
        'href': 'https://local.mickeyllew.co.za/images/mickeyllewfav.png',
        'language': "english",
        'description': "Mickey Llew: SEO Specialists - Cape Town and Johannesburg",
    },
    "reviews.marketingbyrob.com": {
        'title': "Reviews-Marketing By Rob",
        'href': 'https://reviews.marketingbyrob.com/images/faviconMarketing.png',
        'language': "english",
        'description': "Finally A Marketing Agency That Get's You Found & Results!",
    },
    "places.gvp.digital": {
        'title': "Reviews-GVP Solutions",
        'href': 'https://places.gvp.digital/images/favicongvp.png',
        'language': "portuguese",
        'description': "Somos uma empresa de serviços e plataformas digitais considerada pela inovação e personalização que entregamos aos nossos clientes.",
    },
    "app.bizzrep.com": {
        'title': "Reviews-BizzRep",
        'href': 'https://app.bizzrep.com/images/bizzrepFav.ico',
        'language': "english",
        'description': "BizzRep is a review monitoring and management platform that helps businesses generate and respond to new reviews and then manage and analyse them all from one place.",
    },
    "app.localclarity.com.br": {
        'title': "Localclarity",
        'href': 'https://app.localclarity.com.br/images/favicon.ico',
        'language': "portuguese",
        'description': "Localclarity",
    },
    "locations.localorm.com": {
        'title': "Nuance Digital",
        'href': 'https://locations.localorm.com/images/faviconorm.png',
        'language': "english",
        'description': "Quatar Digital Marketing Company-SEO, SMM, Web Development",
    },
    "app.ormanagement.co": {
        'title': "OR Management",
        'href': 'https://app.ormanagement.co/images/favicongr.png',
        'language': "arabic",
        'description': "MSNI Digital marketing Service",
    },
    "cloud.mybusiness.tw": {
        'title': "CloudAD",
        'href': 'https://cloud.mybusiness.tw/images/favicontw.png',
        'language': "english",
        'description': "A Data Driven company Cloud-AD",
    },
    "app.beoordeel.online": {
        'title': "Beoordeel Online",
        'href': 'https://app.beoordeel.online/images/favicnbeoordeel.png',
        'language': "dutch",
        'description': "Beoordeel veilig en eenvoudig bedrijven in de buurt!",
    },
    "canada.localclarity.com": {
        'title': "Canada Localclarity",
        'href': 'https://canada.localclarity.com/images/faviconcanada.png',
        'language': "english",
        'description': "Localclarity",
    },
    "app.localtouch.io": {
        'title': "Reputations-Management Software",
        'href': 'https://app.localtouch.io/images/app.localtouch.io/logo-icon.png',
        'language': "german",
        'description': "Binden Sie alle Ihre Kunden von allen Ihren Standorten aus über ein einziges Bedienfeld ein. Verbinden Sie Google My Business ( GMB ), Facebook, Zomato, Trustpilot, Tripadvisor, Expedia, Booking, AVVO, Lawyers.com, RateMDs, Healthgrades, Indeed, Glassdoor, Edmunds, CarGurus, Apple App Store, Google Play und Dutzende andere Plattformen, um alles in unserer branchenführenden ReviewsInbox™ zusammenzuführen. Verwalten Sie GMB-Fragen und -Antworten standortübergreifend im selben Portal.",
    },
    "stage.localclarity.com": {
        'title': "Stage - Localclarity",
        'href': 'https://stage.localclarity.com/images/favicon.ico',
        'language': "english",
        'description': "Localclarity",
    },
    "reputation.easyfishmarketing.com": {
        'title': "Internet Marketing Experts | Digital Marketing | Easyfish Marketing",
        'href': 'https://reputation.easyfishmarketing.com/images/reputation.easyfishmarketing.com/logo-icon.png',
        'language': "english",
        'description': "Easyfish Marketing helps businesses of all sizes navigate the waters of the digital world. Leave it up to us to help you grow your business.",
    },
    "local.findyouraudience.online": {
        'title': "FYALocal - Reputation Management",
        'href': 'https://local.findyouraudience.online/images/local.findyouraudience.online/logo-icon.png',
        'language': "english",
        'description': "FYALocal, Find Your Audience's local marketing solution",
    },
    "gmn.astradigital.com.br": {
        'title': "Google Meu Negócio Manager - Astra Digital",
        'href': 'https://gmn.astradigital.com.br/images/gmn.astradigital.com.br/logo-icon.png',
        'language': "portuguese",
        'description': "Ajudamos redes com multi localidades a gerenciar a presença digital e reputação nas plataformas de procura.",
    },
    "reputation.itsevolve.com": {
        'title': "Reputation Manager | Evolve Digital",
        'href': 'https://reputation.itsevolve.com/images/reputation.itsevolve.com/logo-icon.png',
        'language': "english",
        'description': "Full Service Reputation Management Platform for Evolve Digital customers.",
    },
    "reviews.flextsms.com": {
        'title': "Flext",
        'href': 'https://reviews.flextsms.com/images/reviews.flextsms.com/logo-icon.png',
        'language': "english",
        'description': "Flext: Mobile messaging automations customized to industry specific needs. Automated features help to drive growth, reviews & memberships.",
    },
    "grow.digimarketer.ca": {
        'title': "Digi Marketer",
        'href': 'https://grow.digimarketer.ca/images/grow.digimarketer.ca/logo-icon.png',
        'language': "english",
        'description': "We help multi-location brands manage their digital presence and reputation on search platforms.",
    },
    "local.leadcrossing.com": {
        'title': "LeadCrossing",
        'href': 'https://local.leadcrossing.com/images/local.leadcrossing.com/logo-icon.png',
        'language': "english",
        'description': "We help brands manage their digital presence and reputation on search platforms, across all of their locations.",
    },
    "reputation.apt.co.ke": {
        'title': "LeadCrossing",
        'href': 'https://reputation.apt.co.ke/images/reputation.apt.co.ke/logo-icon.png',
        'language': "english",
        'description': "Maximize your business returns with Apt Digital Africa's location management solution. Manage all your locations from one dashboard efficiently and build a strong online presence to attract and retain customers. Contact us today to learn more!",
    },
    "reviews.skyeline.com": {
        'title': "SkyeLine Studio",
        'href': 'https://reviews.skyeline.com/images/reviews.skyeline.com/logo-icon.png',
        'language': "english",
        'description': "We help brands manage their digital presence and reputation on search platforms, across all of their locations.",
    },
    "reviews.engageq.com": {
        'title': "engageQ Brand Reputation",
        'href': 'https://reviews.engageq.com/images/reviews.engageq.com/logo-icon.png',
        'language': "english",
        'description': "We help brands manage their digital presence and reputation on search platforms, across all of their locations.",
    },
    "reviews.motivatedmarketing.com": {
        'title': "Motivated Marketing",
        'href': 'https://reviews.motivatedmarketing.com/images/reviews.motivatedmarketing.com/logo-icon.png',
        'language': "english",
        'description': "Motivated Marketing provides comprehensive solutions to help clients establish, grow and maintain their digital presence across various platforms.",
    },
    "control.wela.com": {
        'title': "Wela Control",
        'href': 'https://control.wela.com/images/control.wela.com/logo-icon.png',
        'language': "english",
        'description': "Übernehmen Sie die Kontrolle über die lokalen Profile Ihres Betriebs und Ihrer Bewertungen",
    },
    "reputation.thatsbiz.com": {
        'title': "LSM Specialists",
        'href': 'https://reputation.thatsbiz.com/images/reputation.thatsbiz.com/logo-icon.png',
        'language': "english",
        'description': "Helping restaurant brands improve their online reputation and promote their restaurants locally.",
    },
    "app.getlocaladvantage.com": {
        'title': "Local Advantage",
        'href': 'https://app.getlocaladvantage.com/images/app.getlocaladvantage.com/logo-icon.png',
        'language': "french",
        'description': "Unleash Your Brand's Potential: Elevate Your Online Presence, Dominate Local Search, and Engage More Customers with Our Comprehensive SaaS Solution and Unrivaled Support!",
    },
    "client.googavis.fr": {
        'title': "Googavis",
        'href': 'https://client.googavis.fr/images/client.googavis.fr/logo-icon.png',
        'language': "french",
        'description': "Vos réponses aux avis Google de façon automatisé et professionnelles ",
    },
    "app.googlebusiness.ca": {
        'title': "Google Profile Expert",
        'href': 'https://app.googlebusiness.ca/images/app.googlebusiness.ca/logo-icon.png',
        'language': "english",
        'description': " We help brands manage their digital presence and reputation on search platforms, across all of their locations",
    },
    "app.hospitality-buddy.eu": {
        'title': "Hospitality Buddy",
        'href': 'https://app.hospitality-buddy.eu/images/app.hospitality-buddy.eu/logo-icon.png',
        'language': "dutch",
        'description': "Hospitality Buddy revolutionizes hotel guest experiences by integrating AI-driven solutions, including an AI-powered review responder and a 24/7 chatbot, to provide instant, personalized guest interactions and actionable insights, enhancing both guest satisfaction and operational efficiency.",
    },
    "orm.marinemarketingsolutions.net": {
        'title': "Marine Marketing Solutions",
        'href': 'https://orm.marinemarketingsolutions.net/images/orm.marinemarketingsolutions.net/logo-icon.png',
        'language': "english",
        'description': "Online reputation management for the marine industry.",
    },
    "footfall.opositive.io": {
        'title': "O+ve | Footfall+: Your All-in-One Dashboard for Hyper-Local Growth.",
        'href': 'https://footfall.opositive.io/images/footfall.opositive.io/logo-icon.png',
        'language': "english",
        'description': "Footfall+: Your All-in-One Dashboard for Hyper-Local Growth. Crafted by Opositive, this SAAS platform empowers you to establish a robust digital infrastructure for specialized centers. Monitor real-time progress and gain actionable insights, all from a single, user-friendly dashboard.",
    },
    "reply.asterfive.com": {
        'title': "AsterFive",
        'href': 'https://reply.asterfive.com/images/reply.asterfive.com/logo-icon.png',
        'language': "english",
        'description': "Restaurant Reputation Management",
    },
}
module.exports = {
    languageData: languageMap
}


