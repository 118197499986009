var keyMirror = require('keymirror');

module.exports = keyMirror({
    SAVE_PROFILE_SETTINGS: null,
    PROFILE_SETTINGS_RECEIVED: null,
    PROFILE_SETTINGS_ABSENT: null,
    GET_AR_SETTINGS: null,
    ADD_AR_SETTINGS:null,
    EDIT_AR_SETTINGS:null,
    DELETE_AR_SETTINGS:null,
    VALIDATE_AR_SETTINGS:null,
    GET_TEMPLATES : null
});