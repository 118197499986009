import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
	Layout,
	Icon,
	Tooltip,
	message,
	Modal,
	Dropdown,
	Menu,
	Input,
	Select,
} from 'antd';
import TextSelect from '../sharedComponets/ReactTextSelect';
import GetProfilesStore from '../../routers/AuthorisePage/store/GetProfilesStore';
import ChangeProfileAction from '../../routers/Dashboard/action/ChangeProfileAction';
import ChangeProfileStore from '../../routers/Dashboard/store/ChangeProfileStore';
import GetProfilesAction from '../../routers/AuthorisePage/action/GetProfilesAction';
import GetUserTokenStore from '../../routers/AdminPage/store/GetUserTokenStore';
import LogoutAction from '../../routers/MainFrame/action/LogoutAction';
import GetNavigationDataAction from '../../routers/NavigationLink/action/GetNavigationDataAction';
import GetNavigationDataStore from '../../routers/NavigationLink/store/GetNavigationDataStore';
import FilterSaveAction from '../../routers/Dashboard/action/FilterSaveAction';
import languageFile from '../../../public/languages/Translation';
import urlConfig, { urlName } from '../../config/index';
import GetProfileDetailsAction from '../../routers/AuthorisePage/action/GetProfileDetailsAction';
import GetProfileDetailStore from '../../routers/AuthorisePage/store/GetProfileDetailStore';
import GetProfileSettingsAction from '../../routers/AutoReviewSettings/action/GetProfileSettingsAction';
import GetProfileSettingsStore from '../../routers/AutoReviewSettings/store/GetProfileSettingsStore';
import GetUserDetailsStore from '../../routers/UserDetailPage/store/GetUserDetailsStore';
import { Button } from 'antd';
import cookie from '../../utils/cookie';
import { availableLanguages } from '../../utils/languageConstants';
import GetIntegrationStore from '../../routers/IntegrationControl/store/GetIntegrationStore';
import GetPortfolioDetailsAction from '../../routers/AuthorisePage/action/GetPortfolioDetailsAction';
import GetPortfolioDetailsStore from '../../routers/AuthorisePage/store/GetPortfoliosDetailStore';
import GetPortfolioUsersAction from '../../routers/PortfolioManageUsers/action/GetPortfolioUsersAction';
import GetPortfolioProfilesAction from '../../routers/PortfolioManageProfiles/action/GetPortfolioProfilesAction';
import CheckAdminPaymentAction from '../../routers/AuthorisePage/action/CheckAdminPaymentAction';
import CheckAdminPaymentStore from '../../routers/AuthorisePage/store/CheckAdminPaymentStore';
import GetEditNotificationsAction from './action/GetEditNotificationsAction';
import GetEditNotificationsStore from './store/GetEditNotificationsStore';
import GetLocationEditsAction from './action/GetLocationEditsAction';
import GetLocationEditsStore from './store/GetLocationEditsStore';
import UpdateNotificationAction from './action/UpdateNotificationAction';
import UpdateNotificationStore from './store/UpdateNotificationStore';
import UpdateLocationEditAction from './action/UpdateLocationEditAction';
import UpdateLocationEditStore from './store/UpdateLocationEditStore';
import GetNotificationEditedFieldsAction from './action/GetNotificationEditedFieldsAction';
import GetNotificationEditedFieldsStore from './store/GetNotificationEditedFieldsStore';
import { locationFields } from '../../utils/mapOptions';
import { CustomScrollbar } from '../CustomScrollbar';
import { findLastIndex } from 'lodash';
import { knowledgePanelAddress } from './childrens/generate';
import './style.scss';
import getSVG from '../../utils/commonSVG';
import GetWorkspaceAction from '../../routers/Login/action/GetWorkspaceAction';
const queryString = require('query-string');
var moment = require('moment');
var _ = require('lodash');
const { Header } = Layout;
const { Search } = Input;
const { Option } = Select;
class HeaderBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			profileValue: '',
			showExpireInfo: false,
			currentlanguage: localStorage.getItem('language'),
			navigationGroup: [],
			userImage: '/images/googleavatar.png',
			allSelectedProfileData: {},
			languagesList: [],
			portfolioValue: '',
			currentPage: 1,
			pageSize: 20,
			notifications: [],
			listLocation: false,
			locationData: [],
			tableLoading: false,
			suggestedEdits: false,
			suggestedValue: [],
			currentValue: [],
			notificationCount: 0,
			openNotification: false,
			locationEdits: [],
			singleLocation: false,
			openLocationEdits: false,
			open: 0,
			suggest: false,
			notificationUpdate: false,
			removeNotification: false,
			modal: false,
			copied: false,
			markAsRead: false,
			notificationRemove: false,
			acceptRejectNotificationId: '',
			notificationsLoading: false,
			notificationReadAll: false,
			notificationDropdown: false,
			showChatIcon: true,
			notificationSearchData: '',
			notificationFilter: [],
			currentNotificationFilter: '',
			scrollOn: false,
			filterTotalCount: 0,
			currentDateFormat: {},
			showSuperUserPopup: false
		};
		// this.openNotification = this.openNotification.bind(this)
		this.backToAdmin = this.backToAdmin.bind(this);
		this.handleProfile = this.handleProfile.bind(this);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.onProfileResponse = this.onProfileResponse.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.expireDetails = this.expireDetails.bind(this);
		this.billigPage = this.billigPage.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.onChangeProfile = this.onChangeProfile.bind(this);
		this.onPortfolioResponse = this.onPortfolioResponse.bind(this);
		this.handlePortfolio = this.handlePortfolio.bind(this);
		this.onAdminCheck = this.onAdminCheck.bind(this);
		this.onGetNotifications = this.onGetNotifications.bind(this);
		this.onGetLocationEdits = this.onGetLocationEdits.bind(this);
		this.onUpdateNotification = this.onUpdateNotification.bind(this);
		this.onUpdateLocationEdit = this.onUpdateLocationEdit.bind(this);
		this.dropdownUserRef = React.createRef();
		this.handleOutsideUserClick = this.handleOutsideUserClick.bind(this);
	}

	componentWillMount() {
		GetProfilesStore.bind(this.onProfileResponse);
		GetProfileSettingsStore.bind(this.onSettingsResponse);
		ChangeProfileStore.bind(this.onChangeProfile);
		GetNavigationDataStore.bind(this.onTabSettingsData);
		GetUserDetailsStore.bind(this.onUserDetailsResponse);
		GetIntegrationStore.bind(this.onGetIntegrations);
		GetPortfolioDetailsStore.bind(this.onPortfolioResponse);
		CheckAdminPaymentStore.bind(this.onAdminCheck);
		GetEditNotificationsStore.bind(this.onGetNotifications);
		GetLocationEditsStore.bind(this.onGetLocationEdits);
		UpdateNotificationStore.bind(this.onUpdateNotification);
		UpdateLocationEditStore.bind(this.onUpdateLocationEdit);
		GetNotificationEditedFieldsStore.bind(
			this.onGetNotificationEditedFields
		);
		GetProfileDetailStore.bind(this.getProfileDetailsResponse)
		// GetDataFetchStatusStore.bind(this.onFetchStatus);
	}

	componentWillUnmount() {
		GetProfileSettingsStore.unbind(this.onSettingsResponse);
		GetProfilesStore.unbind(this.onProfileResponse);
		ChangeProfileStore.unbind(this.onChangeProfile);
		GetNavigationDataStore.unbind(this.onTabSettingsData);
		GetUserDetailsStore.unbind(this.onUserDetailsResponse);
		GetIntegrationStore.unbind(this.onGetIntegrations);
		GetPortfolioDetailsStore.bind(this.onPortfolioResponse);
		CheckAdminPaymentStore.unbind(this.onAdminCheck);
		GetEditNotificationsStore.unbind(this.onGetNotifications);
		GetLocationEditsStore.unbind(this.onGetLocationEdits);
		UpdateNotificationStore.unbind(this.onUpdateNotification);
		UpdateLocationEditStore.unbind(this.onUpdateLocationEdit);
		GetNotificationEditedFieldsStore.unbind(
			this.onGetNotificationEditedFields
		);
		GetProfileDetailStore.bind(this.getProfileDetailsResponse)
		document.removeEventListener('click', this.handleDocumentClick);
		document.removeEventListener('mousedown', this.handleOutsideUserClick);

	}

	getProfileDetailsResponse = () => {
		let response = GetProfileDetailStore.getResponse();
		if (response.status == 200) {
			if (cookie.readCookie('superuser') != 'null') {
				cookie.createCookie('superuserrole', response.role);
			}
		}
	}

	handleOutsideUserClick(event) {
		if (this.dropdownUserRef.current && !this.dropdownUserRef.current.contains(event.target)) {
			this.setState({ showSuperUserPopup: false });
		}
	}
	onAdminCheck() {
		if (CheckAdminPaymentStore.getResponse().status === 200) {
			this.setState({
				admin: true,
			});
		} else {
			this.setState({
				admin: false,
				notAdmin: true,
			});
		}
	}
	onGetNotificationEditedFields = () => {
		let notificationFilter = [];
		if (GetNotificationEditedFieldsStore.getResponse().status === 200) {
			let options =
				GetNotificationEditedFieldsStore.getResponse()
					.editedFieldsCount;
			_.map(options, (item, key) => {
				notificationFilter.push({ label: key, value: item });
			});
			this.setState({
				notificationFilter: notificationFilter,
				filterTotalCount:
					GetNotificationEditedFieldsStore.getResponse() &&
						GetNotificationEditedFieldsStore.getResponse().totalCount
						? GetNotificationEditedFieldsStore.getResponse()
							.totalCount
						: 0,
			});
		}
	};
	onChangeNotificationFilter = (event) => {
		this.setState({
			currentNotificationFilter: event,
			scrollOn: false,
		});
		GetEditNotificationsAction.getEditNotifications({
			profileId: localStorage.getItem('profileId'),
			filters: {
				notificationFilters: {
					editedFields: event ? [event] : [],
					searchText: this.state.notificationSearchData,
				},
			},
			from: 0,
			size: 50,
		});
	};
	onGetNotifications() {
		var notifications = this.state.scrollOn ? this.state.notifications : [];
		if (GetEditNotificationsStore.getResponse().status === 200) {
			//notifications = GetEditNotificationsStore.getResponse().notifications
		}
		if (!this.state.openLocationEdits) {
			_.map(
				GetEditNotificationsStore.getResponse().notifications,
				(item, index) => {
					notifications.push({
						// message: item.message ? item.message : '',
						isRead: item.isRead,
						notificationDate: item.createTime,
						locationId: item.locationId ? item.locationId : '',
						locationName: item.locationName
							? item.locationName
							: '',
						notificationType: item.notificationType,
						accountId: item.accountId ? item.accountId : '',
						notificationId: item.notificationId
							? item.notificationId
							: '',
						accountName: item.accountName ? item.accountName : '',
						editCount: item.editCount ? item.editCount : '',
						editedField: item.editedField ? item.editedField : '',
						locationName: item.locationName
							? item.locationName
							: '',
						address: item.address ? item.address : '',
						active: false,
						//roleName:"",
						//roleId:"",
					});
				}
			);
		} else {
			_.map(
				GetEditNotificationsStore.getResponse().notifications,
				(item, index) => {
					notifications.push({
						// message: item.message ? item.message : '',
						isRead: item.isRead,
						notificationDate: item.createTime,
						locationId: item.locationId ? item.locationId : '',
						locationName: item.locationName
							? item.locationName
							: '',
						notificationType: item.notificationType,
						accountId: item.accountId ? item.accountId : '',
						notificationId: item.notificationId
							? item.notificationId
							: '',
						accountName: item.accountName ? item.accountName : '',
						editCount: item.editCount ? item.editCount : '',
						editedField: item.editedField ? item.editedField : '',
						locationName: item.locationName
							? item.locationName
							: '',
						address: item.address ? item.address : '',
						active:
							item.notificationId == this.state.notificationId
								? true
								: false,
					});
				}
			);
		}
		this.setState({
			notifications: notifications,
			notificationsLoading: false,
			notificationCount:
				GetEditNotificationsStore.getResponse().unreadCount,
		});

		//-------------for calling single notification click

		//taking the url

		const params = queryString.parse(this.props.location.search);
		if (params.notification_id) {
			//finding the selected notification 
			const notification = notifications.find(item => item.notificationId === params.notification_id)
			// for scroll to the notification selected
			const element = document.querySelector(`[data-id='${params.notification_id}']`)
			element.scrollIntoView();
			//onClick function of notification
			this.locationSuggestedEdits(notification)

		}
	}
	locationSuggestedEdits = (e) => {
		// if(!this.state.notificationUpdate){
		var notifications = this.state.notifications;
		var notificationCount = this.state.notificationCount;
		//if(!this.state.notificationDropdown){
		_.map(notifications, (content, index) => {
			if (content.notificationId == e.notificationId) {
				notifications[index].active = true;
			} else {
				notifications[index].active = false;
			}
		});

		GetLocationEditsAction.getLocationEdits({
			profileId: localStorage.getItem('profileId'),
			notificationId: e.notificationId,
			locationId: e.locationId,
			size: 20,
		});
		// }
		// if (!e.isRead) {
		//     UpdateNotificationAction.updateNotification({
		//         profileId: localStorage.getItem('profileId'),
		//         notificationUpdateType: 'MARK_AS_READ',
		//         notificationId: e.notificationId,
		//     })
		//     _.map(notifications, (item, index) => {
		//         if(item.notificationId == e.notificationId){
		//             notifications[index].isRead = true
		//             notificationCount = notificationCount - 1
		//         }
		//     });
		// }
		this.setState({
			//openLocationEdits: this.state.notificationUpdate ? this.state.openLocationEdits ? true : false : true,
			singleLocation: true,
			listLocation: true,
			suggestedEdits: true,
			tableLoading: true,
			notificationId: e.notificationId,
			locationId: e.locationId,
			notificationCount: notificationCount,
			notifications: notifications,
			notificationRead: e.isRead,
			notificationDropdown: false,
			// markAsRead: true
		});
		//}
	};
	// locationListingEdits = (e) => {
	//     GetLocationEditsAction.getLocationEdits({
	//         profileId: localStorage.getItem('profileId'),
	//         locationId: e.locationId,
	//         size: 20
	//     })
	//     this.setState({
	//         openLocationEdits: true,
	//         listLocation: true,
	//         tableLoading: true,
	//         suggestedEdits: true
	//     });

	// }
	openLocationEdits = () => {
		var notifications = this.state.notifications;
		_.map(notifications, (content, index) => {
			notifications[index].active = false;
		});
		this.setState({
			openLocationEdits: false,
			notifications: notifications,
			suggest: true,
		});
	};
	// suggestedEditLocation = (item) => {
	//     var notifications = this.state.notifications
	//     _.map(notifications, (content, index) => {
	//         if (content.accountId == item.accountId) {
	//             notifications[index].active = true
	//         } else {
	//             notifications[index].active = false
	//         }
	//     })
	//     GetLocationEditsAction.getLocationEdits({
	//         profileId: localStorage.getItem('profileId'),
	//         accountId: item.accountId,
	//         size: 20
	//     })
	//     this.setState({
	//         notifications: notifications,
	//         openLocationEdits: true,
	//         listLocation: true,
	//         singleLocation: false,
	//         tableLoading: true,
	//         suggestedEdits: false
	//     });
	// }

	toggleSuperUserPopup = () => {
		this.setState((prevState) => ({
			showSuperUserPopup: true,
		}));
	}
	removeNotification = (item, type) => {
		var notifications = this.state.notifications;
		if (
			(type == 'REMOVE' &&
				this.state.notificationId == item.notificationId) ||
			type == 'REMOVE_ALL'
		) {
			this.setState({
				openLocationEdits: false,
				suggest: true,
			});
		}
		// if(type=='MARK_AS_READ'){
		//     this.setState({
		//         markAsRead: true,
		//         removeNotificationId: item ? item.notificationId : null,
		//     });
		// }
		if (type == 'REMOVE') {
			this.setState({
				removeNotificationId: item ? item.notificationId : null,
				notificationRemove: true,
				notificationIsRead: item.isRead,
			});
		}
		if (type == 'READ_ALL') {
			this.setState({
				notificationReadAll: true,
			});
		}
		if (type == 'REMOVE_ALL') {
			this.setState({
				notificationRemoveAll: true,
			});
		}
		UpdateNotificationAction.updateNotification({
			profileId: localStorage.getItem('profileId'),
			notificationUpdateType: type,
			notificationId: item ? item.notificationId : null,
		});
		this.setState({
			notificationUpdate: true,
			removeNotification: true,
		});
	};
	updateLocationEdit = (item, type) => {
		UpdateLocationEditAction.updateLocationEdit({
			profileId: localStorage.getItem('profileId'),
			updateType: type,
			notificationId: item ? item.notificationId : null,
			locationId: item ? item.locationId : null,
			editedField: item.title,
		});
		this.setState({
			acceptRejectNotificationId: item.notificationId,
		});
	};
	fistLocationSuggestedEdits = (e) => {
		GetLocationEditsAction.getLocationEdits({
			profileId: localStorage.getItem('profileId'),
			locationId: e[0].locationId,
			size: 20,
		});
		this.setState({
			suggestedEdits: true,
		});
	};
	getIcon = (field) => {
		if (field == 'storeCode') {
			return <i className='fa fa-archive'></i>;
		} else if (field == 'locationName') {
			return <i className='fa fa-briefcase'></i>;
		} else if (field == 'primaryCategory') {
			return <i className='fa fa-user'></i>;
		} else if (field == 'regularHours') {
			return <i className='fa fa-clock-o' aria-hidden='true'></i>;
		} else if (field == 'specialHours') {
			return <i className='fa fa-calendar' aria-hidden='true'></i>;
		} else if (field == 'websiteUrl') {
			return <i className='fa fa-globe'></i>;
		} else if (field == 'primaryPhone') {
			return <i className='fa fa-phone'></i>;
		} else if (field == 'address') {
			return <i className='fa fa-map-marker'></i>;
		} else if (field == 'attributes') {
			return <i className='fa fa-briefcase'></i>;
		} else if (field == 'labels') {
			return <i className='fa fa-tags'></i>;
		} else if (field == 'additionalPhones') {
			return <i className='fa fa-phone'></i>;
		} else if (field == 'openInfo') {
			return <i class='fa fa-outdent' aria-hidden='true'></i>;
		} else if (field == 'profile') {
			return <i className='fa fa-tag'></i>;
		} else {
			return <i className='fa fa-archive'></i>;
		}
	};
	getSuggestedData = (field, data) => {
		var suggestedValue = [];
		if (field == 'attributes') {
			_.map(data[field], (item, index) => {
				suggestedValue.push({
					suggestedValue: item.attributeId ? item.attributeId : '',
				});
			});
		} else if (field == 'address') {
			if (data[field] && data[field].addressLines) {
				suggestedValue.push({
					suggestedValue: data[field].addressLines,
				});
			} else {
				suggestedValue.push({ suggestedValue: '' });
			}
		} else if (field == 'openInfo') {
			if (data[field] && data[field].status) {
				suggestedValue.push({ suggestedValue: data[field].status });
			} else {
				suggestedValue.push({ suggestedValue: '' });
			}
		} else if (field == 'primaryCategory') {
			if (data[field] && data[field].displayName) {
				suggestedValue.push({
					suggestedValue: data[field].displayName,
				});
			} else {
				suggestedValue.push({ suggestedValue: '' });
			}
		} else if (field == 'profile') {
			if (data[field] && data[field].description) {
				suggestedValue.push({
					suggestedValue: data[field].description,
				});
			} else {
				suggestedValue.push({ suggestedValue: '' });
			}
		} else if (field == 'regularHours') {
			if (data[field] && data[field].periods) {
				_.map(data[field].periods, (item, index) => {
					suggestedValue.push({
						suggestedValue:
							item.closeDay +
							' ' +
							' : ' +
							' ' +
							item.openTime +
							'-' +
							item.closeTime,
					});
				});
			} else {
				suggestedValue.push({ suggestedValue: '' });
			}
		} else if (field == 'specialHours') {
			if (data[field] && data[field].specialHourPeriods) {
				_.map(
					data['specialHours'].specialHourPeriods,
					(item, index) => {
						if (item.hasOwnProperty('isClosed')) {

							let specialHours = item.startDate.month + '-' + item.startDate.day + '-' + item.startDate.year + ' ' + ' : ' + ' ' + 'closed';

							suggestedValue.push({
								suggestedValue: `${moment(specialHours.split(' : ')[0]).format(this.state.currentDateFormat.value)} : ${specialHours.split(' : ')[1]}`
							})
						} else {
							let specialHours = item.endDate.month + '-' + item.endDate.day + '-' + item.endDate.year + ' ' + ' : ' + ' ' + item.openTime + '-' + item.closeTime;

							suggestedValue.push({
								suggestedValue: `${moment(specialHours.split(' : ')[0]).format(this.state.currentDateFormat.value)} : ${specialHours.split(' : ')[1]}`
							})
						}
					}
				);
			} else {

				suggestedValue.push({ suggestedValue: '' });
			}
		} else if (field == 'postalAddress') {
			suggestedValue.push({ suggestedValue: data[field] });
		} else if (field == 'relationshipData') {
			_.map(data['relationshipData'], (item, index) => {
				suggestedValue.push({ suggestedValue: item });
			});
		} else if (field == 'additionalCategories') {
			_.map(data['additionalCategories'], (item, index) => {
				suggestedValue.push({
					suggestedValue: item.displayName ? item.displayName : '',
				});
			});
		} else {
			suggestedValue.push({ suggestedValue: data[field] });
		}
		return suggestedValue;
	};
	onUpdateLocationEdit() {
		if (UpdateLocationEditStore.getResponse().status === 200) {
			message.success(UpdateLocationEditStore.getResponse().message);
			// GetEditNotificationsAction.getEditNotifications(
			//     {
			//         profileId: localStorage.getItem('profileId'),
			//         from: 0,
			//         size: 1000
			//     });
		} else {
			message.warning(UpdateLocationEditStore.getResponse().data.message);
		}
		GetLocationEditsAction.getLocationEdits({
			profileId: localStorage.getItem('profileId'),
			notificationId: this.state.notificationId,
			locationId: this.state.locationId,
			size: 20,
		});
	}
	onUpdateNotification() {
		var notifications = this.state.notifications;
		var notificationCount = this.state.notificationCount;
		if (UpdateNotificationStore.getResponse().status === 200) {
			message.success(UpdateNotificationStore.getResponse().message);
			if (this.state.notificationRemoveAll) {
				var notifications = [];
				this.setState({
					notifications: notifications,
					openLocationEdits: false,
				});
				GetEditNotificationsAction.getEditNotifications({
					profileId: localStorage.getItem('profileId'),
					filters: {
						notificationFilters: {
							editedFields: [],
							searchText: '',
						},
					},
					from: 0,
					size: 50,
				});
			}
			if (this.state.notificationReadAll) {
				notificationCount = 0;
				_.map(notifications, (item, index) => {
					notifications[index].isRead = true;
				});
			}
			// if(this.state.markAsRead){
			//     _.map(notifications, (item, index) => {
			//         if(item.notificationId == this.state.removeNotificationId){
			//             notifications[index].isRead = true
			//             //notificationCount = notificationCount - 1
			//         }
			//     });
			// }
			if (this.state.notificationRemove) {
				var removeIndex = notifications
					.map(function (item) {
						return item.notificationId;
					})
					.indexOf(this.state.removeNotificationId);
				notifications.splice(removeIndex, 1);
				if (!this.state.notificationIsRead) {
					notificationCount = notificationCount - 1;
				}
			}
		} else {
			message.warning(UpdateNotificationStore.getResponse().data.message);
		}
		this.setState({
			notificationUpdate: false,
			markAsRead: false,
			notificationRemove: false,
			notifications: notifications,
			notificationCount: notificationCount,
			notificationReadAll: false,
		});
	}
	showLocationName = (item) => {
		if (localStorage.getItem('masterLocationFilter') == 'true') {
			return (
				<>
					<Tooltip
						title={
							languageFile[localStorage.getItem('language')][
							'25271'
							]
						}>
						<a
							href={
								'/managelocations/' +
								encodeURIComponent(item.locationId)
							}>
							{item.locationName}
						</a>
					</Tooltip>
				</>
			);
		} else {
			return (
				<div className='store-details'>
					<Tooltip
						title={
							languageFile[localStorage.getItem('language')][
							'25271'
							]
						}>
						<a
							href={
								'/managelocations/' +
								encodeURIComponent(item.locationId)
							}>
							{item.locationName}
						</a>
					</Tooltip>
					{item.storeCode && (
						<span className='store-code' style={{ paddingLeft: "unset" }}>
							{item.storeCode || '-'}
						</span>
					)}
				</div>
			);
		}
	};
	gotoSite = (siteurl) => {
		window.open(siteurl, '_blank');
	};
	gotoMaps = (mapsUrl) => {
		window.open(mapsUrl, '_blank');
	};
	scrolled = (e) => {
		const bottom =
			e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
		var notifications = this.state.notifications;
		if (bottom < 1 && notifications.length > 0) {
			GetEditNotificationsAction.getEditNotifications({
				profileId: localStorage.getItem('profileId'),
				filters: {
					notificationFilters: {
						editedFields: this.state.currentNotificationFilter
							? [this.state.currentNotificationFilter]
							: [],
						searchText: this.state.notificationSearchData,
					},
				},
				//filters:{"notificationFilters":{"editedFields":[],"searchText":""}},
				from: notifications.length,
				size: 50,
			});
		}
		this.setState({
			scrollOn: true,
		});
	};
	getSuggestedAttributesData = (currentValue, suggestedValue) => {
		var suggestedAttributesData = [];
		_.map(suggestedValue['attributes'], (item, index) => {
			var newAttribute = true;
			_.map(currentValue['attributes'], (content, key) => {
				if (item.attributeId == content.attributeId) {
					newAttribute = false;
					suggestedAttributesData.push({
						suggestedValue: item.attributeId
							? item.attributeId
							: '',
						newAttribute: newAttribute,
					});
				}
			});
			if (newAttribute) {
				suggestedAttributesData.push({
					suggestedValue: item.attributeId ? item.attributeId : '',
					newAttribute: newAttribute,
				});
			}
		});
		return suggestedAttributesData;
	};
	onGetLocationEdits() {
		var locationData = [];
		var locationEdits = [];
		var notifications = this.state.notifications;
		var notificationCount = this.state.notificationCount;
		var activeMarkAsRead = true;
		if (GetLocationEditsStore.getResponse().status === 200) {
			if (GetLocationEditsStore.getResponse().total > 0) {
				locationData = _.map(
					GetLocationEditsStore.getResponse().locationData,
					(item, index) => {
						return {
							index: index,
							locationName: item.locationName
								? item.locationName
								: '',
							locationId: item.masterLocationId
								? item.masterLocationId
								: item.locationId,
							accountName: item.accountName
								? item.accountName
								: '',
							address: item.address ? item.address : '',
							storeCode: item.storeCode ? item.storeCode : '',
							websiteUrl: item.websiteUrl ? item.websiteUrl : '',
							mapsUrl: item.mapsUrl ? item.mapsUrl : '',
						};
					}
				);
				this.setState({
					locationData: locationData,
					actionStatus: GetLocationEditsStore.getResponse()
						.locationData[0].updateStatus
						? GetLocationEditsStore.getResponse().locationData[0]
							.updateStatus
						: 'NO_ACTIONS',
					suggestedValue:
						GetLocationEditsStore.getResponse().locationData[0]
							.editedValues,
					currentValue:
						GetLocationEditsStore.getResponse().locationData[0]
							.locationValues,
					listLocation: false,
					tableLoading: false,
					locationShareLink:
						GetLocationEditsStore.getResponse().locationData[0]
							.locationShareLink,
				});
			}
		}
		if (GetLocationEditsStore.getResponse().total > 0) {
			locationEdits = _.map(
				Object.getOwnPropertyNames(this.state.currentValue),
				(item, index) => {
					var fields = Object.keys(locationFields);
					var a = false;
					_.map(fields, (content, index) => {
						if (item == content) {
							a = true;
						}
					});
					if (a) {
						return {
							index: index,
							title: item,
							icon: this.getIcon(item),
							currentValue:
								item == 'attributes'
									? this.getSuggestedAttributesData(
										this.state.suggestedValue,
										this.state.currentValue
									)
									: this.getSuggestedData(
										item,
										this.state.currentValue
									),
							suggestedValue:
								item == 'attributes'
									? this.getSuggestedAttributesData(
										this.state.currentValue,
										this.state.suggestedValue
									)
									: this.getSuggestedData(
										item,
										this.state.suggestedValue
									),
							isChecked: false,
							notificationId: this.state.notificationId,
							locationId: this.state.locationId,
						};
					}
				}
			);
			_.map(locationEdits, (item, index) => {
				if (this.state.actionStatus != 'NO_ACTIONS') {
					_.map(
						Object.getOwnPropertyNames(this.state.actionStatus),
						(content, field) => {
							if (item.title == content) {
								locationEdits[index].status =
									this.state.actionStatus[content];
							}
							if (
								this.state.actionStatus[content] == 'NO_ACTIONS'
							) {
								activeMarkAsRead = false;
							}
						}
					);
				} else {
					locationEdits[index].status = 'NO_ACTIONS';
					activeMarkAsRead = false;
				}
			});

			if (activeMarkAsRead && !this.state.notificationRead) {
				this.setState({
					markAsRead: true,
				});
				UpdateNotificationAction.updateNotification({
					profileId: localStorage.getItem('profileId'),
					notificationUpdateType: 'MARK_AS_READ',
					notificationId: this.state.acceptRejectNotificationId,
				});
				_.map(notifications, (item, index) => {
					if (
						item.notificationId ==
						this.state.acceptRejectNotificationId
					) {
						notifications[index].isRead = true;
						notificationCount = notificationCount - 1;
					}
				});
			}
			this.setState({
				locationEdits: locationEdits,
				openLocationEdits: this.state.removeNotification
					? this.state.openLocationEdits
						? true
						: false
					: true,
				removeNotification: false,
				acceptRejectNotificationId: '',
				notifications: notifications,
				notificationCount: notificationCount,
			});
		} else if (this.state.acceptRejectNotificationId) {
			var removeIndex = notifications
				.map(function (item) {
					return item.notificationId;
				})
				.indexOf(this.state.acceptRejectNotificationId);
			notifications.splice(removeIndex, 1);
			this.setState({
				locationEdits: [],
				openLocationEdits: false,
				suggest: true,
				notifications: notifications,
				acceptRejectNotificationId: '',
			});
		} else {
			message.warning(GetLocationEditsStore.getResponse().data.message);
		}
	}
	onGetIntegrations = () => {
		this.setState({
			languagesList: GetIntegrationStore.getResponse().domainLanguages,
		});
	};
	onUserDetailsResponse = () => {
		this.setState({
			userImage: '/images/googleavatar.png',
		});
	};
	handleDocumentClick = (e) => {
		if (
			!document.getElementById('modal-content').contains(e.target) &&
			e.target.className !=
			'ant-select-dropdown-menu-item ant-select-dropdown-menu-item-selected' &&
			e.target.className != 'ant-tooltip-open'
		) {
			if (
				!document.getElementById('notification-list').contains(e.target)
			) {
				var openNotification = this.state.openNotification;
				var openLocationEdits = this.state.openLocationEdits;
				if (
					openNotification &&
					!openLocationEdits &&
					this.state.open == 1 &&
					!this.state.suggest &&
					!this.state.notificationUpdate &&
					!this.state.bell
				) {
					this.setState({
						openNotification: false,
						openLocationEdits: false,
						open: 0,
						notificationSearchData: '',
						notificationFilter: [],
						currentNotificationFilter: '',
						scrollOn: false,
					});
					this.notificationActiveUpdate();
					document.body.style.overflow = 'auto';
				} else if (this.state.suggest) {
					this.setState({
						suggest: false,
					});
				}
			}
			if (
				!document
					.getElementById('notification-inner')
					.contains(e.target)
			) {
				var openNotification = this.state.openNotification;
				if (openNotification && this.state.open == 0) {
					this.setState({
						openNotification: true,
						open: 1,
					});
				} else if (
					openNotification &&
					this.state.open == 1 &&
					!this.state.notificationUpdate &&
					!this.state.bell
				) {
					this.setState({
						openNotification: false,
						openLocationEdits: false,
						open: 0,
						notificationSearchData: '',
						notificationFilter: [],
						currentNotificationFilter: '',
						scrollOn: false,
					});
					this.notificationActiveUpdate();
					document.body.style.overflow = 'auto';
				}
			}
			this.setState({
				bell: false,
			});
		}
	};
	componentDidMount() {

		let params = queryString.parse(this.props.location.search);
		document.addEventListener('mousedown', this.handleOutsideUserClick);
		if (params.type) {
			this.openNotificationBell();
		}


		var domain = null;
		GetProfilesAction.getProfiles(cookie.readCookie('lctoken'));
		GetPortfolioDetailsAction.getPortfolios(cookie.readCookie('lctoken'));
		CheckAdminPaymentAction.check(domain);
		GetNotificationEditedFieldsAction.getNotificationEditedFields({
			profileId: localStorage.getItem('profileId'),
		});
		GetEditNotificationsAction.getEditNotifications({
			profileId: localStorage.getItem('profileId'),
			filters: {
				notificationFilters: { editedFields: [], searchText: '' },
			},
			from: 0,
			size: 50,
		});
		document.addEventListener('click', this.handleDocumentClick);
		document.getElementById('notification').onclick = function (e) {
			e.preventDefault();
			document.body.style.overflow = 'hidden';
		};
		document.getElementById('copyButton').onmouseout = function (e) {
			e.preventDefault();
			this.setState({ copied: false });
		}.bind(this);
		// document.getElementById('notification-dropdown').onclick = function (e) {
		//     e.preventDefault();
		//     this.setState({notificationDropdown: true})
		// }.bind(this)
		// let item=document.getElementsByClassName('intercom-lightweight-app')
		// if(item[0]&&item[0].style['display']==='none'){
		// 	this.setState({showChatIcon:false})
		// }
		let item;
		if (urlConfig.urlName == 'app.localclarity.com.br') {
			item = document.getElementsByClassName('cc-1xry cc-1gmp');
			if (item[0] && item[0].style['display'] === 'none') {
				this.setState({ showChatIcon: false });
			}
		} else {
			item = document.getElementsByClassName('intercom-lightweight-app');
			item = [...item, document.getElementById('intercom-container')];
			if (item[0] && item[0].style['display'] === 'none') {
				this.setState({ showChatIcon: false });
			}
		}
	}
	onTabSettingsData = () => {
		if (GetNavigationDataStore.getResponse().status === 200) {
			let hits = GetNavigationDataStore.getResponse().hits;
			let headerLinks = hits.filter(
				(item) => item.linkPosition && item.linkPosition == 'header'
			);
			this.setState({
				navigationGroup: headerLinks,
			});
		}
	};
	onChangeProfile() {
		GetNavigationDataAction.getData(localStorage.getItem('profileId'));
		GetProfileSettingsAction.getSettings(localStorage.getItem('profileId'));
		GetEditNotificationsAction.getEditNotifications({
			profileId: localStorage.getItem('profileId'),
			filters: {
				notificationFilters: { editedFields: [], searchText: '' },
			},
			from: 0,
			size: 50,
		});
		GetNotificationEditedFieldsAction.getNotificationEditedFields({
			profileId: localStorage.getItem('profileId'),
		});
		this.setState({
			profileValue: ChangeProfileStore.getResponse().profileName,
			notifications: [],
			notificationSearchData: '',
			notificationFilter: [],
			currentNotificationFilter: '',
			scrollOn: false,
		});
		var link =
			document.querySelector("link[rel*='icon']") ||
			document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = localStorage.getItem('favicon');
		document.getElementsByTagName('head')[0].appendChild(link);
		localStorage.removeItem('platformRequestId');
		localStorage.removeItem('googleRequestId');
		localStorage.removeItem('attributesRequestId');
		localStorage.removeItem('facebookRequestId');
		localStorage.removeItem('googlelocationRequestId');
		localStorage.removeItem('facebooklocationRequestId');
	}

	backToAdmin() {
		cookie.eraseCookie('lctoken');
		cookie.createCookie("lctoken",
			cookie.readCookie('superuser'), 2);
		cookie.eraseCookie('superuser');
		cookie.eraseCookie('superusername');
		cookie.eraseCookie('superuserimage');
		cookie.eraseCookie("superuserrole");
		cookie.readCookie('workspaceDataSuperUser') && cookie.createCookie('workspaceData', cookie.readCookie('workspaceDataSuperUser'));
		cookie.readCookie('currentAccountIdSuperUser') && cookie.createCookie('currentAccountId', cookie.readCookie('currentAccountIdSuperUser'));
		cookie.eraseCookie('workspaceDataSuperUser');
		cookie.eraseCookie('currentAccountIdSuperUser');
		GetWorkspaceAction.getWorkspace();
		this.props.history.push('/authorize');
	}

	onProfileResponse() {
		GetProfileSettingsAction.getSettings(localStorage.getItem('profileId'))
		if (GetProfilesStore.getResponse()) {
			var profiles = GetProfilesStore.getResponse().map(function (item) {
				return item.profile.profileName;
			});
			// let sortedList = profiles.sort();
			let sortedList = _.sortBy(profiles, [
				function (o) {
					return o && o.toLowerCase();
				},
			]);
			this.setState({
				profileOptions: sortedList,
				profileValue: localStorage.getItem('profileName'),
			});
		}
		if (localStorage.getItem('profileName')) {
			var selectedProfiledetails;
			var allSelectedProfileData;
			GetProfilesStore.getResponse() && GetProfilesStore.getResponse().map(function (item) {
				if (
					item.profile.profileName ===
					localStorage.getItem('profileName')
				) {
					selectedProfiledetails = item.profile;
				}
			});
			GetProfilesStore.getResponse() && GetProfilesStore.getResponse().map(function (item) {
				if (
					item.profile.profileName ===
					localStorage.getItem('profileName')
				) {
					allSelectedProfileData = item;
				}
			});
			this.setState({
				selectedProfiledetails: selectedProfiledetails,
				allSelectedProfileData: allSelectedProfileData,
			});
		}
	}

	onPortfolioResponse() {
		var portfolios = [];
		if (GetPortfolioDetailsStore.getResponse()) {
			_.map(GetPortfolioDetailsStore.getResponse(), (item, key) => {
				portfolios.push(item.portfolioName);
			});
			// var portfolios = GetPortfolioDetailsStore.getResponse().map(function (item) {
			//     return item.portfolioName
			// })
			// let sortedList = profiles.sort();
			let sortedList = _.sortBy(portfolios, [
				function (o) {
					return o && o.toLowerCase();
				},
			]);
			this.setState({
				portfolioOptions: sortedList,
				portfolioValue: localStorage.getItem('portfolioName'),
			});
		}
	}
	onLogout() {
		LogoutAction.log(cookie.readCookie('lctoken'));
		localStorage.removeItem('profileId');
		localStorage.removeItem('profileName');
		localStorage.removeItem('masterLocationFilter');
		localStorage.removeItem('userImage');
		cookie.eraseCookie('lctoken');
		cookie.eraseCookie('userName');
		cookie.eraseCookie('userId');
		cookie.eraseCookie('superuser');
		cookie.eraseCookie('superusername');
		cookie.eraseCookie('superuserimage');
		cookie.eraseCookie("superuserrole");
		FilterSaveAction.clearFilter();
		// var auth2 = gapi.auth2.getAuthInstance();
		// auth2.signOut().then(function () {
		// this.props.history.push("/login");
		window.location.href = '/login';
		// });
		//this.props.history.push("/login");
	}

	handleProfile(value) {
		this.setState({
			profileValue: value,
		});
		var profileId = _.find(GetProfilesStore.getResponse(), function (item) {
			return item.profile.profileName === value;
		}).profile.profileId;
		var masterLocationFilter = _.find(
			GetProfilesStore.getResponse(),
			function (item) {
				return item.profile.profileName === value;
			}
		).profile.masterLocationFilter;
		var profileExpiry = _.find(
			GetProfilesStore.getResponse(),
			function (item) {
				return item.profile.profileName === value;
			}
		).profile.paymentExpired;
		var selectedProfiledetails;
		var allSelectedProfileData;
		GetProfilesStore.getResponse().map(function (item) {
			if (item.profile.profileName === value) {
				selectedProfiledetails = item.profile;
			}
		});
		GetProfilesStore.getResponse().map(function (item) {
			if (item.profile.profileName === value) {
				allSelectedProfileData = item;
			}
		});
		this.setState({
			selectedProfiledetails: selectedProfiledetails,
			allSelectedProfileData: allSelectedProfileData,
		});
		if (profileExpiry && !this.state.admin) {
			localStorage.setItem('profileId', profileId);
			localStorage.setItem('profileName', value);
			localStorage.setItem('masterLocationFilter', masterLocationFilter);
			this.props.history.push('/authorize/paymentExpired');
			message.info('plan Expired.');
		} else {
			localStorage.setItem('profileId', profileId);
			localStorage.setItem('profileName', value);
			localStorage.setItem('masterLocationFilter', masterLocationFilter);
			localStorage.setItem(
				'favicon',
				selectedProfiledetails.favicon ||
				`/images/${urlConfig.urlName}/logo-icon.png`
			);
			localStorage.setItem(
				'logo',
				selectedProfiledetails.logo ||
				`/images/${urlConfig.urlName}/logo-icon.png`
			);
			localStorage.setItem(
				'logoText',
				selectedProfiledetails.logoWithText ||
				`/images/${urlConfig.urlName}/logo-text.png`
			);
			ChangeProfileAction.changeProfile({
				profileId: profileId,
				profileName: value,
				masterLocationFilter: masterLocationFilter,
			});
			GetProfileDetailsAction.getProfileDetails(profileId, urlName);
		}
	}
	onSettingsResponse = () => {
		this.setState({
			currentDateFormat: GetProfileSettingsStore.getResponse().dateFormat,
		})
	}

	handlePortfolio(value) {
		this.setState({
			portfolioValue: value,
		});
		var portfolioId = _.find(
			GetPortfolioDetailsStore.getResponse(),
			function (item) {
				return item.portfolioName === value;
			}
		).portfolioId;
		var portfolioName = _.find(
			GetPortfolioDetailsStore.getResponse(),
			function (item) {
				return item.portfolioName === value;
			}
		).portfolioName;
		//var portfolioLogo = GetPortfolioDetailsStore.getResponse().portfolioLogo ? _.find(GetPortfolioDetailsStore.getResponse(), function (item) { return item.portfolioName === value }).portfolioLogo : null;
		var portfolioLogo = _.find(
			GetPortfolioDetailsStore.getResponse(),
			function (item) {
				return item.portfolioName === value;
			}
		).portfolioLogo;

		localStorage.setItem('portfolioId', portfolioId);
		localStorage.setItem('portfolioName', portfolioName);
		localStorage.setItem('portfolioLogo', portfolioLogo);
		GetPortfolioProfilesAction.getPortfolioProfiles(
			localStorage.getItem('portfolioId')
		);
		GetProfilesAction.getProfiles(cookie.readCookie('lctoken'));
		GetPortfolioUsersAction.getPortfolioUsers(
			localStorage.getItem('portfolioId'),
			(this.state.currentPage - 1) * this.state.pageSize,
			this.state.pageSize
		);
	}
	handleVisibleChange() {
		this.setState({
			visible: !this.state.visible,
		});
	}

	reload() {
		var urlName = urlConfig.urlName;
		window.location = '/feedback?domainName=' + urlName;
		// location.reload();
	}
	expireDetails() {
		this.setState({
			showExpireInfo: true,
		});
	}
	billigPage() {
		this.props.history.push('/settings/payment');
		this.setState({
			showExpireInfo: false,
		});
	}
	handleCancel() {
		this.setState({
			showExpireInfo: false,
			listLocation: false,
			suggestedEdits: false,
			suggestedValue: [],
			currentValue: [],
		});
	}
	cookierRead() {
		if (
			cookie.readCookie('userName') &&
			cookie.readCookie('userName') !== 'undefined'
		) {
			return cookie.readCookie('userName');
		} else {
			return cookie.readCookie('userId');
		}
	}
	chooseOption = (option) => {
		if (option.key == 'edit') {
			// this.makeEditable(item)
		} else if (option.key == 'delete') {
			// this.deleteNote(item)
		}
	};
	changelanguage = (e) => {
		localStorage.setItem('language', e.key);
		this.setState({
			currentlanguage: e.key,
		});
		this.props.onChangeLanguage(e.key);
	};
	fullScreenBrowserWindow = () => {
		document.documentElement.requestFullscreen();
	};
	notificationActiveUpdate = () => {
		var notifications = this.state.notifications;
		_.map(notifications, (item, index) => {
			notifications[index].active = false;
		});
	};
	openNotification = () => {
		var openNotification = this.state.openNotification;
		this.setState({
			openNotification: !openNotification,
			openLocationEdits: false,
			open: !openNotification ? 0 : 1,
		});
		this.notificationActiveUpdate();
		document.body.style.overflow = 'auto';

		//for removing queryParams while clicking close button
		this.props.history.replace(this.props.location.pathname)
	};





	openNotificationBell = () => {
		var openNotification = this.state.openNotification;
		if (!openNotification) {
			var notifications = [];
			this.setState({
				notifications: notifications,
			});
			GetNotificationEditedFieldsAction.getNotificationEditedFields({
				profileId: localStorage.getItem('profileId'),
				filters: {
					notificationFilters: {
						searchText: this.state.notificationSearchData,
					},
				},
			});
			GetEditNotificationsAction.getEditNotifications({
				profileId: localStorage.getItem('profileId'),
				//filters:{"notificationFilters":{"editedFields":[],"searchText":""}},
				filters: {
					notificationFilters: {
						editedFields: this.state.currentNotificationFilter
							? [this.state.currentNotificationFilter]
							: [],
						searchText: this.state.notificationSearchData,
					},
				},
				from: 0,
				size: 50,
			});
		}
		this.setState({
			openNotification: true,
			//openLocationEdits: false,
			notificationsLoading: openNotification ? false : true,
			bell: true,
		});




	};
	customNavRendering = (item, keys) => {
		if (item.linkType === 'INTERNAL') {
			return (
				<div className='custom-links__item' key={keys}>
					<Tooltip
						title={item.tooltipComment}
						placement='top'
						key={keys}>
						<Link to={'/iframe?externallink=' + item.link}>
							<span className='nav-icon'>
								<img
									src={
										'https://' +
										urlConfig.urlName +
										'/api/' +
										item.imageFile +
										'?param=' +
										moment().format('LTS')
									}
								/>
							</span>
							<span className='nav-label'>
								{' '}
								{_.truncate(item.tabName, { length: 14 })}
							</span>
						</Link>
					</Tooltip>
				</div>
			);
		} else {
			return (
				<div className='custom-links__item' key={keys}>
					<Tooltip
						title={item.tooltipComment}
						placement='top'
						key={keys}>
						<a target='_blank' href={item.link}>
							<span className='nav-icon'>
								<img
									src={
										'https://' +
										urlConfig.urlName +
										'/api/' +
										item.imageFile +
										'?param=' +
										moment().format('LTS')
									}
								/>
							</span>
							<span className='nav-label'>
								{' '}
								{_.truncate(item.tabName, { length: 14 })}
							</span>
						</a>
					</Tooltip>
				</div>
			);
		}
	};
	gotoProfile = () => {
		this.props.history.push(
			'/settings/inviteUser/userdetails/' +
			btoa(cookie.readCookie('userId'))
		);
	};
	shareYourBussiness = () => {
		this.setState({ modal: !this.state.modal });
	};
	copyCodeToClipboard = () => {
		const el = this.textArea;
		el.select();
		document.execCommand('copy');
		this.setState({ copied: true });
	};
	render() {
		const popupSuperUserStyle = this.state.showSuperUserPopup
			? { display: 'block', position: 'absolute', width: '22rem', top: '30px', right: '10px', borderRadius: '5px', marginTop: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }
			: { display: 'none' };
		var urlName = urlConfig.urlName;
		let self = this;
		const menu = (
			<Menu onClick={this.changelanguage} className='lang-list'>
				{_.map(availableLanguages, (item, key) => {
					if (this.state.languagesList.includes(key)) {
						return <Menu.Item key={key}>{item}</Menu.Item>;
					}
				})}
			</Menu>
		);
		if (localStorage.getItem('language') === 'portuguese') {
			moment.locale('pt');
		} else if (localStorage.getItem('language') === 'english') {
			moment.locale('en');
		} else if (localStorage.getItem('language') === 'german') {
			moment.locale('de');
		} else if (localStorage.getItem('language') === 'spanish') {
			moment.locale('es');
		} else if (localStorage.getItem('language') === 'french') {
			moment.locale('fr');
		} else if (localStorage.getItem('language') === '日本語') {
			moment.locale('ja');
		}
		const toggleChatIcon = () => {
			let item;
			if (urlName == 'app.localclarity.com.br') {
				item = document.getElementsByClassName('cc-1xry cc-1gmp');
			} else {
				item = document.getElementsByClassName(
					'intercom-lightweight-app'
				);
				item = [...item, document.getElementById('intercom-container')];
			}
			if (item[0]) {
				item[0].setAttribute(
					'style',
					this.state.showChatIcon
						? 'display: none !important'
						: 'display: ""'
				);
			}
			this.setState({ showChatIcon: !this.state.showChatIcon });
		};
		const onNotificationSearch = (searchData) => {
			this.setState({
				notificationSearchData: searchData,
				scrollOn: false,
			});
			GetEditNotificationsAction.getEditNotifications({
				profileId: localStorage.getItem('profileId'),
				filters: {
					notificationFilters: {
						editedFields: this.state.currentNotificationFilter
							? [this.state.currentNotificationFilter]
							: [],
						searchText: searchData,
					},
				},
				from: 0,
				size: 50,
			});
			GetNotificationEditedFieldsAction.getNotificationEditedFields({
				profileId: localStorage.getItem('profileId'),
				filters: {
					notificationFilters: {
						searchText: searchData,
					},
				},
			});
		};
		return (
			<div
				className='header-container'
				style={{
					backgroundColor: localStorage.getItem('headerBackground'),
				}}>
				<div
					className={
						(this.props.isSideBarVisible && 'slide-icon') ||
						'slide-icon'
					}
					style={{ color: localStorage.getItem('headerIcon') }}>
					<Tooltip
						title={
							languageFile[localStorage.getItem('language')][
							'13837'
							]
						}
						placement='right'>
						<i
							className='material-icons'
							onClick={this.props.toggleSideBar}>
							dehaze
						</i>
					</Tooltip>
				</div>
				<div className='edit-icon'>
					<Tooltip title={'Hide/Show Nav bar'} placement='right'>
						<i
							onClick={this.props.onChangeVisibility}
							style={{
								color: localStorage.getItem('headerIcon'),
							}}
							className='fa fa-arrows-h'></i>
					</Tooltip>
				</div>
				{/* <div className="edit-icon">
                    <Tooltip title={languageFile[localStorage.getItem('language')]["10923"]} placement="right">
                        <Link to="/authorize"><i style={{ color: localStorage.getItem("headerIcon") }} className="material-icons">create</i></Link>
                    </Tooltip>
                </div> */}
				{this.state.profileValue && this.props.manageProfile && (
					<div className='dropdown-wrapper'>
						<TextSelect
							options={this.state.profileOptions}
							active={this.state.profileValue}
							onChange={this.handleProfile}
							enableSearch={false}
						/>
					</div>
				)}
				{this.state.portfolioValue && this.props.managePortfolio && (
					<div className='dropdown-wrapper'>
						<TextSelect
							options={this.state.portfolioOptions}
							active={this.state.portfolioValue}
							onChange={this.handlePortfolio}
							enableSearch={false}
						/>
					</div>
				)}
				<ul className='header-right'>
					{this.state.selectedProfiledetails &&
						!this.state.selectedProfiledetails.subscriptionId &&
						this.state.selectedProfiledetails.trialEndTime && (
							<li>
								<a
									className='header-link'
									onClick={this.expireDetails}>
									<Tooltip
										title={`${languageFile[
											localStorage.getItem('language')
										]['24041']
											}  ${this.state.selectedProfiledetails
												.trialEndTime &&
											this.state.selectedProfiledetails.trialEndTime.split(
												' '
											)[0]
											} `}
										placement='left'>
										<span style={{ color: '#F15922' }}>
											<i
												className='fa fa-exclamation-triangle'
												aria-hidden='true'></i>
										</span>
									</Tooltip>
								</a>
							</li>
						)}
					{this.state.navigationGroup.length > 0 && (
						<li>
							<a className='dropdown header-link'>
								<Dropdown
									overlay={
										<Menu>
											<Menu.Item
												key={'keys'}
												className='custom-wrapper'>
												<div className='custom-links'>
													{_.map(
														this.state
															.navigationGroup,
														function (item, keys) {
															return self.customNavRendering(
																item,
																keys
															);
														}
													)}
												</div>
											</Menu.Item>
										</Menu>
									}
									trigger={['click']}
									onClick={this.test}>
									<a style={{ border: 0 }}>
										<span className='custom-navicon'>
											<svg viewBox='0 0 218.001 216.004'>
												<path
													data-name='Path 3959'
													d='M10189,18046h-96v-96h96v96Zm-86-10h76v-76h-76Zm154,7v-37h-37v-13h37v-38h13v38h38v13h-38v37Zm54-117h-96v-96h96Zm-86-10h76v-76h-76Zm-36,10h-96v-96h96Zm-86-10h76v-76h-76Z'
													transform='translate(-10092.998 -17830)'
													fill={localStorage.getItem(
														'headerIcon'
													)}
												/>
											</svg>
										</span>
									</a>
								</Dropdown>
							</a>
						</li>
					)}
					<li>
						<a className='dropdown header-link'>
							<Icon
								onClick={this.fullScreenBrowserWindow}
								type='fullscreen'
								style={{
									color: localStorage.getItem('headerIcon'),
									cursor: 'pointer',
								}}
							/>
						</a>
					</li>
					<li>
						<a className='dropdown language-selection header-link'>
							<Dropdown overlay={menu}>
								<Button
									style={{
										color: localStorage.getItem(
											'headerIcon'
										),
									}}>
									{
										availableLanguages[
										this.state.currentlanguage
										]
									}{' '}
									<Icon type='down' />
								</Button>
							</Dropdown>
						</a>
					</li>
					{(urlName == 'dev.localclarity.com' ||
						urlName == 'stage.localclarity.com' ||
						urlName == 'app.localclarity.com' ||
						urlName == 'localhost:3000') && (
							<li className='dropdown'>
								<Tooltip title='Support' placement='bottom'>
									<a
										className='dropdown-toggle header-link'
										data-toggle='dropdown'>
										<i
											style={{
												color: localStorage.getItem(
													'headerIcon'
												),
											}}
											className='fa fa-life-ring'></i>
									</a>
								</Tooltip>
								<ul className='dropdown-menu'>
									<li>
										<a
											// href=' https://intercom.help/localclarity'
											href='https://support.localclarity.com/portal/en/kb'
											target='_blank'>
											<span>Knowledge Base</span>
										</a>
									</li>
									<li>
										<a
											// href='https://localclarity.canny.io/feature-requests'
											href='https://app.loopedin.io/localclarity-mB-o/ideas'
											target='_blank'>
											<span>Feature Requests</span>
										</a>
									</li>
									<li>
										<a
											href={
												window.location.origin + '/helpdesk'
											}
											target='_blank'>
											<span>Help Desk</span>
										</a>
									</li>
								</ul>
							</li>
						)}
					{(urlName == 'dev.localclarity.com' ||
						urlName == 'stage.localclarity.com' ||
						urlName == 'app.localclarity.com' ||
						urlName == 'localhost:3000'
					) && (
							<li>
								<Tooltip
									title={
										!this.state.showChatIcon
											? 'Enable chatbot'
											: 'Disable chatbot'
									}
									placement='bottom'>
									<span
										onClick={toggleChatIcon}
										className={'toggle-chat'}>
										{this.state.showChatIcon
											? getSVG(
												'chat-active',
												localStorage.getItem(
													'headerIcon'
												)
											)
											: getSVG('chat-inactive')}
									</span>
								</Tooltip>
							</li>
						)}
					<li
						style={{
							color: localStorage.getItem('headerIcon'),
						}}>
						{cookie.readCookie('superuser') && (
							<a className='back-to-admin header-link'>
								<span onClick={this.backToAdmin}>
									{
										languageFile[
										localStorage.getItem('language')
										]['10321']
									}
								</span>
							</a>
						)}
					</li>
					<li className='notification'>
						<a
							id='notification'
							class='header-link'
							onClick={self.openNotificationBell}>
							<Tooltip title='Notification' placement='bottom'>
								{getSVG(
									'notification-active',
									localStorage.getItem('headerIcon')
								)}
							</Tooltip>
							{this.state.notificationCount != 0 && (
								<span class='button-badge'>
									{this.state.notificationCount}
								</span>
							)}
						</a>
						<div
							className={
								!this.state.openNotification
									? 'notification-menu'
									: 'notification-menu open'
							}
							style={{
								backgroundColor:
									this.state.openLocationEdits &&
									'rgba(76, 77, 79, 0.5)',
							}}>
							<div
								className='notification-inner'
								id='notification-inner'>
								<div
									className='notification-list'
									id='notification-list'>
									<div className='notification-header'>
										<h3>GMB Community Suggestions</h3>
										<span
											className='notification-clearall'
											onClick={self.removeNotification.bind(
												self,
												'',
												'REMOVE_ALL'
											)}>
											Clear all
										</span>
										<span
											onClick={self.openNotification}
											style={{ cursor: 'pointer' }}>
											<svg
												viewBox='64 64 896 896'
												focusable='false'
												class=''
												data-icon='close'
												width='1em'
												height='1em'
												fill='currentColor'
												aria-hidden='true'>
												<path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
											</svg>
										</span>
									</div>
									<ul className='notification-search'>
										<li>
											<Search
												placeholder='Search'
												value={
													this.state
														.notificationSearchData
												}
												onChange={(e) =>
													this.setState({
														notificationSearchData:
															e.target.value,
													})
												}
												onSearch={onNotificationSearch}
											/>
										</li>
										<li>
											<Select
												className='notification-filter'
												placeholder={
													'All Suggestions' +
													' (' +
													this.state
														.filterTotalCount +
													')'
												}
												value={
													this.state
														.currentNotificationFilter
														? this.state
															.currentNotificationFilter
														: 'All Suggestions' +
														' (' +
														this.state
															.filterTotalCount +
														')'
												}
												allowClear
												showSearch={true}
												onChange={
													this
														.onChangeNotificationFilter
												}>
												{this.state.notificationFilter.map(
													(item) => (
														<Option
															key={item.label}
															value={item.label}>
															<Tooltip
																title={
																	locationFields[
																	item
																		.label
																	] +
																	' (' +
																	item.value +
																	')'
																}>
																{locationFields[
																	item.label
																] +
																	' (' +
																	item.value +
																	')'}
															</Tooltip>
														</Option>
													)
												)}
											</Select>
										</li>
									</ul>
									<CustomScrollbar
										onScroll={this.scrolled}
										className='notification-body'
										style={{ height: '0px' }}>
										{this.state.notificationsLoading && (
											<div className='loader-main'>
												<div className='loader'></div>
											</div>
										)}
										{!this.state.notificationsLoading && (
											<ul className='notification-body__item'>
												{_.map(
													this.state.notifications,
													function (item, key) {
														return (
															<li
																style={
																	item.active
																		? {
																			backgroundColor:
																				'#FFFCED',
																		}
																		: {
																			borderBottom:
																				'1px solid #e9eef2',
																		}
																}
																data-id={item.notificationId}
																onClick={self.locationSuggestedEdits.bind(
																	self,
																	item
																)}>
																{item && (
																	<>
																		<div className='notification-content'>
																			{item.locationName && (
																				<>
																					<div className='notification-content__details'>
																						<h3>
																							{
																								item.locationName
																							}
																						</h3>
																						{item.address && (
																							<p>
																								<i
																									class='fa fa-map-marker'
																									aria-hidden='true'></i>
																								<span>
																									{item
																										.address
																										.addressLines
																										? item
																											.address
																											.addressLines[0]
																										: ''}

																									,{' '}
																									{item
																										.address
																										.locality
																										? item
																											.address
																											.locality
																										: ''}

																									,{' '}
																									{item
																										.address
																										.administrativeArea
																										? item
																											.address
																											.administrativeArea
																										: ''}

																									,{' '}
																									{item
																										.address
																										.regionCode
																										? item
																											.address
																											.regionCode
																										: ''}{' '}
																								</span>
																							</p>
																						)}
																						<p
																							style={
																								item.isRead
																									? {
																										color: '#202124',
																										fontWeight:
																											'400',
																									}
																									: {
																										color: '#202124',
																										fontWeight:
																											'600',
																									}
																							}>
																							<i
																								class='fa fa-circle-o-notch color-orange'
																								aria-hidden='true'></i>
																							<span>
																								{item.notificationType ==
																									'location'
																									? `${item.editCount} fields`
																									: `Change ${locationFields[
																										item
																											.editedField
																									]
																										? locationFields[
																										item
																											.editedField
																										]
																										: item.editedField
																									}`}
																							</span>
																						</p>
																					</div>
																				</>
																			)}
																		</div>
																		<div className='notification-controls'>
																			<Tooltip
																				title={moment(
																					item.notificationDate
																				).format(
																					'lll'
																				)}
																				placement='top'>
																				<span className='notification-date'>
																					{moment(
																						item.notificationDate
																					).format(
																						'MMM D'
																					)}
																				</span>
																			</Tooltip>
																			<span className='notification-dropdown'>
																				<Dropdown
																					style={{
																						border: '0px',
																					}}
																					overlay={
																						<Menu>
																							<Menu.Item
																								key='1'
																								onClick={self.removeNotification.bind(
																									self,
																									item,
																									'REMOVE'
																								)}>
																								Remove
																							</Menu.Item>
																							{/* <Menu.Item key='2' onClick={self.removeNotification.bind(self, item, 'MARK_AS_READ')}>
                                                                            Mark as read
			                                                    </Menu.Item> */}
																						</Menu>
																					}>
																					<span>
																						<i
																							class='fa fa-ellipsis-v'
																							aria-hidden='true'
																							style={{
																								color: '#6f6d6d',
																							}}></i>
																					</span>
																				</Dropdown>
																			</span>
																		</div>
																	</>
																)}
															</li>
														);
													}
												)}
											</ul>
										)}
									</CustomScrollbar>
									<div className='notification-footer'></div>
									{/* <div className='notification-footer' onClick={self.removeNotification.bind(self, '', 'READ_ALL')}>Mark all as read</div> */}
								</div>
								<div
									className={
										!this.state.openLocationEdits
											? 'notification-suggestion'
											: 'notification-suggestion show'
									}>
									<div className='location-details'>
										<div className='location-details__header'>
											<h3>Suggested Location Changes</h3>
											<span
												onClick={
													self.openLocationEdits
												}>
												<svg
													viewBox='64 64 896 896'
													focusable='false'
													class=''
													data-icon='close'
													width='1em'
													height='1em'
													fill='currentColor'
													aria-hidden='true'>
													<path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
												</svg>
											</span>
										</div>
										<CustomScrollbar
											className='location-details__body'
											style={{ height: '0px' }}>
											<ul className='location-details-item'>
												{_.map(
													this.state.locationEdits,
													(item, key) => {
														return (
															<li className='table-location-detail'>
																{/* {item && <> */}
																<div className='table-location-detail__title'>
																	<span>
																		{
																			item.icon
																		}
																	</span>
																</div>
																<div className='table-location-detail__content'>
																	<h2>
																		{locationFields[
																			item
																				.title
																		]
																			? locationFields[
																			item
																				.title
																			]
																			: item.title}
																	</h2>
																	<ul className='table-location-detail__content--dateblock'>
																		<li>
																			<span>
																				Current
																				Value
																			</span>
																			{item.title !=
																				'attributes' ? (
																				<>
																					{_.map(
																						item.currentValue,
																						(
																							item,
																							index
																						) => {

																							return (
																								<span>
																									{item.suggestedValue ? item.suggestedValue : '-'}
																								</span>
																							);
																						}
																					)}{' '}
																				</>
																			) : (
																				<>
																					{_.map(
																						item.currentValue,
																						(
																							item,
																							index
																						) => {
																							return (
																								<span
																									style={{
																										color: item.newAttribute
																											? 'red'
																											: '',
																									}}>
																									{item.suggestedValue
																										? item.suggestedValue
																										: '-'}
																								</span>
																							);
																						}
																					)}{' '}
																				</>
																			)}
																		</li>
																		<li>
																			<span>
																				New
																				Value
																			</span>
																			{item.title !=
																				'attributes' ? (
																				<>
																					{_.map(
																						item.suggestedValue,
																						(
																							item,
																							index
																						) => {
																							return (
																								<span
																									style={{
																										color: '#e37400',
																									}}>
																									{item.suggestedValue ? item.suggestedValue : '-'}
																								</span>
																							);
																						}
																					)}{' '}
																				</>
																			) : (
																				<>
																					{_.map(
																						item.suggestedValue,
																						(
																							item,
																							index
																						) => {
																							return (
																								<span
																									style={{
																										color: item.newAttribute
																											? '#e37400'
																											: '#06a7e1',
																									}}>
																									{
																										item.suggestedValue
																									}
																								</span>
																							);
																						}
																					)}{' '}
																				</>
																			)}
																		</li>
																	</ul>
																	<div className='table-location-detail__content--footer'>
																		{item.status ==
																			'NO_ACTIONS' && (
																				<>
																					<button
																						class='ant-btn ant-btn-default'
																						onClick={self.updateLocationEdit.bind(
																							self,
																							item,
																							'REJECT'
																						)}>
																						Reject
																					</button>
																					<button
																						class='ant-btn ant-btn-primary'
																						onClick={self.updateLocationEdit.bind(
																							self,
																							item,
																							'ACCEPT'
																						)}>
																						Accept
																					</button>
																				</>
																			)}
																		{item.status !=
																			'NO_ACTIONS' && (
																				<span className='action-status'>
																					{item.status ==
																						'ACCEPT'
																						? 'Accepted'
																						: item.status == 'AUTO_ACCEPTED' ? 'Auto Accepted'
																							: item.status == 'AUTO_REJECTED' ? 'Auto Rejected'
																								: 'Rejected'}
																				</span>
																			)}
																	</div>
																</div>
																{/* </>} */}
															</li>
														);
													}
												)}
											</ul>
										</CustomScrollbar>
										<div className='location-details-footer'></div>
									</div>
									<div className='location-list'>
										<div className='location-list__detais'>
											{_.map(
												this.state.locationData,
												function (item, key) {
													return (
														<>
															<div className='review-detail'>
																<div className='review-detail__header header-bg'>
																	<h3>
																		{self.showLocationName(
																			item
																		)}
																	</h3>
																	<div className='location'>
																		<a
																			href={knowledgePanelAddress(
																				item,
																				''
																			)}
																			target='_blank'>
																			<img
																				src='/images/googlemap-icon.png'
																				width='20'
																			/>
																			{item.address && (
																				<span>
																					<Tooltip
																						title={
																							languageFile[
																							localStorage.getItem(
																								'language'
																							)
																							][
																							'28801'
																							]
																						}>
																						{
																							item
																								.address
																								.addressLines[0]
																						}

																						,{' '}
																						{
																							item
																								.address
																								.locality
																						}

																						,{' '}
																						{
																							item
																								.address
																								.administrativeArea
																						}

																						,{' '}
																						{
																							item
																								.address
																								.regionCode
																						}
																					</Tooltip>
																				</span>
																			)}
																		</a>
																	</div>
																</div>
															</div>
															{item.websiteUrl && (
																<div className='location-website'>
																	<Tooltip title='Website'>
																		<i className='fa fa-globe'></i>
																	</Tooltip>
																	<Tooltip
																		title={
																			item.websiteUrl
																		}>
																		<a
																			href='#'
																			onClick={self.gotoSite.bind(
																				self,
																				item.websiteUrl
																			)}>
																			{_.truncate(
																				item.websiteUrl,
																				{
																					length: 30,
																				}
																			)}
																		</a>
																	</Tooltip>
																</div>
															)}
															<div className='location-search'>
																<h3>
																	Your
																	business is
																	live on
																	Google
																</h3>
																<ul className='location-search__item'>
																	<li>
																		<a
																			href={knowledgePanelAddress(
																				item,
																				''
																			)}
																			target='_blank'>
																			<Tooltip
																				title={
																					languageFile[
																					localStorage.getItem(
																						'language'
																					)
																					][
																					'20491'
																					]
																				}>
																				<span>
																					<img src='/images/googless.png' />
																				</span>
																			</Tooltip>
																			<span>
																				View
																				on
																				Search
																			</span>
																		</a>
																	</li>
																	<li>
																		<a
																			href='#'
																			onClick={self.gotoMaps.bind(
																				self,
																				item.mapsUrl
																			)}
																			target='_blank'>
																			<Tooltip
																				title={
																					languageFile[
																					localStorage.getItem(
																						'language'
																					)
																					][
																					'20511'
																					]
																				}>
																				<span>
																					<img src='/images/googlemap-icon.png' />
																				</span>
																			</Tooltip>
																			<span>
																				{' '}
																				View
																				on
																				Map
																			</span>
																		</a>
																	</li>
																	<li>
																		<a
																			href='#'
																			onClick={self.shareYourBussiness.bind(
																				self
																			)}>
																			<Tooltip title='share'>
																				<span>
																					<svg viewBox='0 0 24 24'>
																						<path
																							d='M0 0h24v24H0V0z'
																							fill='none'
																						/>
																						<path d='M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z' />
																					</svg>
																				</span>
																			</Tooltip>
																			<span>
																				Share
																				your
																				Business
																				Profile
																			</span>
																		</a>
																	</li>
																</ul>
															</div>
														</>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li
						className='dropdown'
						style={{ color: localStorage.getItem('headerIcon') }}>
						{cookie.readCookie("superuser") && cookie.readCookie("superusername") &&
							cookie.readCookie("superuserimage") &&
							(cookie.readCookie("superuserimage") != 'null' && cookie.readCookie("superusername") != 'null') ?
							<span className='super-user-block'><Tooltip title={cookie.readCookie("superusername")}><img src={cookie.readCookie("superuserimage")} onClick={this.toggleSuperUserPopup} /></Tooltip> </span> :
							cookie.readCookie("superusername") != null ?
								<span className='super-user-block'><Tooltip title={cookie.readCookie("superusername")}><span className={'super-user-image'} onClick={this.toggleSuperUserPopup}>
									{cookie.readCookie("superusername").split(' ')[0].charAt(0).toUpperCase() + (cookie.readCookie("superusername").split(' ')[1] ?
										cookie.readCookie("superusername").split(' ')[1].charAt(0).toUpperCase() : '')}
								</span></Tooltip></span> : <></>}
						<a
							className='dropdown-toggle header-link'
							data-toggle='dropdown'>
							<span className='user-avatar'>
								<img
									src={
										localStorage.getItem('userImage') +
										'?time=' +
										moment().format('LTS')
									}
								/>
							</span>
						</a>
						<ul className='dropdown-menu'>
							<li className='dropdown'>
								<div className='popup-image'>
									<span className='avatar-popup'>
										<img
											src={
												localStorage.getItem(
													'userImage'
												) +
												'?time=' +
												moment().format('LTS')
											}
										/>
									</span>
									<h3>{this.cookierRead()}</h3>
									<span>{cookie.readCookie('userId')}</span>
									<span>
										{(this.state.allSelectedProfileData &&
											this.state.allSelectedProfileData
												.roleName &&
											this.state.allSelectedProfileData
												.roleName) ||
											(this.state
												.allSelectedProfileData &&
												this.state
													.allSelectedProfileData
													.role &&
												this.state
													.allSelectedProfileData
													.role) ||
											''}
									</span>
								</div>
							</li>
							{this.state.profileValue &&
								this.props.manageProfile && (
									<li>
										<a onClick={this.gotoProfile}>
											<Icon type='user' />
											<span>
												{
													languageFile[
													localStorage.getItem(
														'language'
													)
													]['13192']
												}
											</span>
										</a>
									</li>
								)}
							<li onClick={this.onLogout}>
								<a>
									<Icon type='logout' />
									<span>
										{
											languageFile[
											localStorage.getItem('language')
											]['12380']
										}
									</span>
								</a>
							</li>
						</ul>
					</li>
				</ul>
				<ul className={'dropdown-super-user-menu'}>
					<li className="dropdown">
						<div className="dropdown-menu" style={popupSuperUserStyle} ref={this.dropdownUserRef}>
							<div className={'super-user-popup'}>
								<div className={'header'}>
									Navigating as
								</div>
								<div className={'user-section'}>
									<div className={'image'}>{cookie.readCookie("superuserimage") != 'null' && cookie.readCookie("superuserimage") != null ?
										<img src={cookie.readCookie("superuserimage")} /> :
										cookie.readCookie("superusername") != 'null' && cookie.readCookie("superusername") != null &&
										<span className={'super-user-image'} onClick={this.toggleSuperUserPopup}>
											{cookie.readCookie("superusername").split(' ')[0].charAt(0).toUpperCase() + (cookie.readCookie("superusername").split(' ')[1] ?
												cookie.readCookie("superusername").split(' ')[1].charAt(0).toUpperCase() : '')}
										</span>
									}</div>
									<div className={'details'}>
										<span className={'name'}>
											{cookie.readCookie("superusername")}
										</span>
										<span className={'email'}>
											{cookie.readCookie("superuserid")}
										</span>
										<span className={'role'}>
											{cookie.readCookie("superuserrole") != 'null' && cookie.readCookie("superuserrole") != 'undefined' && cookie.readCookie("superuserrole")}
										</span>
									</div>
								</div>
							</div>
						</div>
					</li></ul>
				<Modal
					visible={this.state.showExpireInfo}
					title={
						languageFile[localStorage.getItem('language')]['24051']
					}
					onOk={this.billigPage}
					onCancel={this.handleCancel}
					width={500}
					footer={
						<div>
							<Button
								key='submit'
								type='primary'
								onClick={this.billigPage}>
								{
									languageFile[
									localStorage.getItem('language')
									]['24081']
								}
							</Button>
							<Button
								key='cancel'
								type='default'
								onClick={this.handleCancel}>
								{
									languageFile[
									localStorage.getItem('language')
									]['24091']
								}
							</Button>
						</div>
					}>
					<p style={{ paddingBottom: 10, paddingTop: 10 }}>
						{
							languageFile[localStorage.getItem('language')][
							'24061'
							]
						}{' '}
						{this.state.selectedProfiledetails &&
							this.state.selectedProfiledetails.trialEndTime &&
							moment(
								this.state.selectedProfiledetails.trialEndTime.split(
									' '
								)[0]
							).format('ll')}
					</p>
					<p>
						{
							languageFile[localStorage.getItem('language')][
							'24071'
							]
						}
					</p>
				</Modal>
				<div
					className='modal'
					onClick={this.shareYourBussiness}
					style={{ display: this.state.modal ? 'block' : 'none' }}>
					<div
						className='modal-content'
						id='modal-content'
						onClick={(e) => e.stopPropagation()}>
						<span className='close'>
							<svg
								onClick={this.shareYourBussiness}
								viewBox='64 64 896 896'
								focusable='false'
								class=''
								data-icon='close'
								width='1em'
								height='0.7em'
								fill='currentColor'
								aria-hidden='true'>
								<path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
							</svg>
						</span>
						<div className='share-business-header'>
							<h1>Share Business Profile</h1>
							<h3>Link to find your business on Google</h3>
						</div>
						<div className='share-business-body'>
							<span className='share-business-body__copy'>
								<Tooltip
									title={
										this.state.copied
											? 'Copied'
											: 'Copy to clipboard'
									}>
									<span>
										{!this.state.copied ? (
											<i
												class='fa fa-clone'
												aria-hidden='true'
												id='copyButton'
												onClick={
													this.copyCodeToClipboard
												}
												onmouseout='mouselog(event)'></i>
										) : (
											<i
												style={{ color: 'blue' }}
												class='fa fa-check'
												aria-hidden='true'></i>
										)}
									</span>
								</Tooltip>
							</span>
							<input
								style={{
									minHeight: '60px',
									minWidth: '380px',
									borderRadius: '6px',
									paddingRight: '40px',
									paddingLeft: '5px',
									border: '1px solid',
									borderColor: '#202124',
								}}
								ref={(input) => (this.textArea = input)}
								value={this.state.locationShareLink}
							/>
						</div>
						<div className='share-business-body__items'>
							<li>
								<a
									href={`https://www.facebook.com/sharer/sharer.php?u= ${this.state.locationShareLink}`}
									target='_blank'>
									<img src='/images/facebook-reviews.png' />
									<span> Facebook</span>
								</a>
							</li>
							<li>
								<a
									href={`https://api.whatsapp.com/send?text= ${this.state.locationShareLink}`}
									target='_blank'>
									<img src='/images/whatsapp.svg' />
									<span> Whatsapp</span>
								</a>
							</li>
							<li>
								<a
									href={`https://twitter.com/intent/tweet?text= ${this.state.locationShareLink}`}
									target='_blank'>
									<img
										style={{ borderRadius: '50%' }}
										src='/images/twitter.png'
									/>
									<span> Twitter</span>
								</a>
							</li>
						</div>
						<div className='share-via-email'>
							<a
								href={`mailto: ${this.state.locationShareLink}`}
								target='_blank'>
								<img />
								<span aria-hidden='true'>
									<svg
										width='20'
										height='20'
										viewBox='0 0 24 24'
										focusable='false'
										class=' NMm5M'>
										<path d='M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.8 2L12 10.8 4.8 6h14.4zM4 18V7.87l8 5.33 8-5.33V18H4z'></path>
									</svg>
								</span>
								<span style={{ marginLeft: '5px' }}>
									Share via email
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(HeaderBar);
